<template>
  <main id="content" class="reservation">
    <router-view></router-view>
  </main>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';

export default {
  name: 'Reser',
  mixins: [mixinHelperUtils],
};
</script>
